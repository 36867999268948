import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data }) => {

  // Process data
  const courseList = data.CourseList.edges.reduce((acc, cur) => 
  acc.concat({ ...cur.node.exports.coursedata, ...cur.node.frontmatter }), [])
  const pageInfo = {
  type: "page",
  courseId: null,
  sectionId: null,
  lessonId: null,
  }

  return (
    <Layout pageInfo={pageInfo} courseList={courseList}>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>We can't find this page.</p>
    </Layout>
  );

}

export default NotFoundPage

export const query = graphql`
query NotFoundQuery {
  CourseList: allMdx(filter: {frontmatter: {type: {eq: "course"}}}, sort: {fields: frontmatter___courseId, order: ASC}) {
    edges {
      node {
        exports {
          coursedata {
            courseId
            courseShortTitle
            courseTitle
            courseShortDescription
          }
        }
        frontmatter {
          slug
          type
        }
      }
    }
  }
}
`